import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
//import { progress } from '../ui/progress';
import { Clock, CheckCircle, Star, Zap } from 'lucide-react';

interface UserAchievementsProps {
    taskStats: {
      todo: number;
      inProgress: number;
      done: number;
    } | null;
    taskAnalytics: {
      totalTasks: number;
      completedTasks: number;
      pendingTasks: number;
      overdueTasks: number;
      averageCompletionTime: number;
    } | null;
    timeStats: {
      totalTime: number;
      completedTime: number;
    } | null;
  }

const UserAchievements: React.FC<UserAchievementsProps> = ({ taskStats, taskAnalytics, timeStats }) => {
  const { t } = useTranslation();

  if (!taskStats || !taskAnalytics || !timeStats) {
    return <div>{t('loading')}</div>; // 添加加载状态
  }

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} ${t('days')} ${hours % 24} ${t('hours')}`;
    }
    return `${hours} ${t('hours')} ${minutes % 60} ${t('minutes')}`;
  };

  const overallProgress = taskAnalytics.totalTasks > 0 
    ? (taskAnalytics.completedTasks / taskAnalytics.totalTasks) * 100 
    : 0;

  return (
    <div className="space-y-6">
      <Card className="bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white overflow-hidden">
        <CardContent className="pt-6">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-3xl font-bold mb-2">{t('timeWithBanBan')}</h2>
              <p className="text-lg opacity-90">{t('youveCompletedTasksFor')}</p>
              <p className="text-5xl font-bold mt-2">{formatTime(timeStats.completedTime)}</p>
            </div>
            <Clock size={100} className="opacity-20" />
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card className="bg-gradient-to-br from-green-400 to-cyan-500 text-white">
          <CardHeader>
            <CardTitle className="flex items-center">
              <CheckCircle size={24} className="mr-2" />
              {t('tasksCompleted')}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <span className="text-5xl font-bold">{taskStats.done}</span>
          </CardContent>
        </Card>
        
        <Card className="bg-gradient-to-br from-amber-400 to-orange-500 text-white">
          <CardHeader>
            <CardTitle className="flex items-center">
              <Zap size={24} className="mr-2" />
              {t('overallProgress')}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <progress value={overallProgress} max={100} className="w-full mb-2 [&::-webkit-progress-bar]:bg-white/20 [&::-webkit-progress-value]:bg-white [&::-moz-progress-bar]:bg-white" />
            <p className="text-center text-2xl font-bold">{overallProgress.toFixed(1)}% {t('completed')}</p>
          </CardContent>
        </Card>

        <Card className="bg-gradient-to-br from-pink-400 to-red-500 text-white">
          <CardHeader>
            <CardTitle className="flex items-center">
              <Star size={24} className="mr-2" />
              {t('taskCompletionRate')}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <span className="text-5xl font-bold">
              {(taskAnalytics.completedTasks / taskAnalytics.totalTasks * 100).toFixed(1)}%
            </span>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-2xl">{t('yourProductivity')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="font-semibold text-lg mb-2">{t('totalTaskTime')}</p>
              <p className="text-3xl font-bold text-primary">{formatTime(timeStats.totalTime)}</p>
            </div>
            <div>
              <p className="font-semibold text-lg mb-2">{t('averageCompletionTime')}</p>
              <p className="text-3xl font-bold text-primary">
                {formatTime(taskAnalytics.averageCompletionTime * 24 * 60)}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserAchievements;