import React from 'react';

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
  thickness?: number;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ 
  size = 40, 
  color = '#3B82F6', // Tailwind blue-500
  thickness = 4
}) => {
  const spinnerStyle: React.CSSProperties = {
    width: size,
    height: size,
    animation: 'rotate 1s linear infinite',
  };

  const circleStyle: React.CSSProperties = {
    stroke: color,
    strokeWidth: thickness,
    strokeLinecap: 'round',
    strokeDasharray: '80, 200',
    strokeDashoffset: 0,
  };

  return (
    <div style={spinnerStyle}>
      <svg
        viewBox="0 0 50 50"
        style={{ width: '100%', height: '100%' }}
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          style={circleStyle}
        />
      </svg>
      <style>
        {`
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinner;