import * as React from "react"
import { Toaster as HotToaster, toast as hotToast } from "react-hot-toast"

export function Toaster() {
  return <HotToaster />
}

type ToastProps = {
  title: string;
  description: string;
  variant?: 'default' | 'destructive';
};

export const useToast = () => {
  const toast = (props: ToastProps) => {
    hotToast(
      <div>
        <div className="font-bold">{props.title}</div>
        <div>{props.description}</div>
      </div>,
      {
        duration: 3000,
        style: {
          background: props.variant === 'destructive' ? '#38bdf8' : '#60a5fa',
          color: 'white',
          padding: '16px',
          borderRadius: '8px',
        },
      }
    )
  }

  return { toast }
}