import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { getUserTaskStats, getTasks, getTaskAnalytics, getTaskTimeStats } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
//import { Button } from '../components/ui/button';
import { Task } from '../types/task';
import TaskSummaryCharts from '../components/function/TaskSummaryCharts';
import UserAchievements from '../components/function/UserAchievements';
import { format } from 'date-fns';
import { useApi } from '../hooks/useApi';

type TaskStats = {
  todo: number;
  inProgress: number;
  done: number;
} | null;

type TaskAnalytics = {
  totalTasks: number;
  completedTasks: number;
  pendingTasks: number;
  overdueTasks: number;
  averageCompletionTime: number;
} | null;

type TimeStats = {
  totalTime: number;
  completedTime: number;
} | null;

const Dashboard: React.FC = () => {
const { t } = useTranslation();
const [taskStats, setTaskStats] = useState<TaskStats>(null);
const [recentTasks, setRecentTasks] = useState<Task[]>([]);
const [taskAnalytics, setTaskAnalytics] = useState<TaskAnalytics>(null);
const [timeStats, setTimeStats] = useState<TimeStats>(null);
const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState<string | null>(null);
const api = useApi();


useEffect(() => {
  fetchDashboardData();
}, []);

const fetchDashboardData = async () => {
  setIsLoading(true);
  setError(null);
  try {
    const stats = await api.getUserTaskStats();
    setTaskStats({
      todo: stats.todo || 0,
      inProgress: stats.in_progress || 0,
      done: stats.done || 0
    });

    const tasks = await api.getTasks();
    setRecentTasks(tasks.filter(task => task.status === 'done').slice(0, 5));
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3); // 1个月前
    const endDate = new Date();
    const analytics = await api.getTaskAnalytics(startDate.toISOString(), endDate.toISOString());
    setTaskAnalytics(analytics);

    const timeStatsData = await api.getTaskTimeStats();
    setTimeStats(timeStatsData);
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    setError(t('errorFetchingData'));
  } finally {
    setIsLoading(false);
  }
};

if (isLoading) {
  return <div>{t('loading')}</div>;
}

if (error) {
  return <div>{error}</div>;
}

return (
  <div className="container mx-auto p-4">
    <h1 className="text-3xl font-bold mb-6">{t('dashboard')}</h1>
    
    <UserAchievements 
      taskStats={taskStats}
      taskAnalytics={taskAnalytics}
      timeStats={timeStats}
    />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <Card>
          <CardHeader>
            <CardTitle>{t('recentCompletions')}</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {recentTasks.map((task) => (
                <li key={task.id} className="flex justify-between items-center">
                  <span>{task.title}</span>
                  <span className="text-sm text-gray-500">
                    {format(new Date(task.updatedAt), 'PP')}
                  </span>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>

        <TaskSummaryCharts taskStats={taskStats} taskAnalytics={taskAnalytics} />
      </div>

    </div>
  );
};

export default Dashboard;