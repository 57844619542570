import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Task } from '../../types/task';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/card';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PRIORITY_VALUES, getPriorityText } from '../../utils/taskUtils';
import { addDays } from 'date-fns';

interface CreateTaskFormProps {
  onCreateTask: (task: Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'subtasks' | 'userId'>) => Promise<void>;
  onClose: () => void;
}

const CreateTaskForm: React.FC<CreateTaskFormProps> = ({ onCreateTask, onClose }) => {
  const { t } = useTranslation();
  const [newTask, setNewTask] = useState<Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'subtasks' | 'userId'>>({
    title: '',
    description: '',
    status: 'todo',
    estimatedTime: 0,
    remainingTime: 0,
    completionPercentage: 0,
    actualTime: 0,
    dueDate: addDays(new Date(), 5).toISOString(),
    priority: PRIORITY_VALUES.MEDIUM,
    demo: '',
    guidance: '',
    order: '0',
  });
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewTask(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setNewTask(prev => ({ ...prev, dueDate: date.toISOString() }));
    }
  };

  const handlePriorityChange = (value: string) => {
    setNewTask(prev => ({ ...prev, priority: Number(value) }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (!newTask.title.trim()) {
      setError(t('titleRequired'));
      return;
    }
    try {
      await onCreateTask(newTask);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : t('anErrorOccurred'));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>{t('createNewTask')}</CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardContent className="space-y-4">
            {error && <div className="text-red-500">{error}</div>}
            <Input
              name="title"
              placeholder={t('taskTitle')}
              value={newTask.title}
              onChange={handleInputChange}
              required
            />
            <Textarea
              name="description"
              placeholder={t('taskDescription')}
              value={newTask.description}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
            <Select
              value={newTask.priority.toString()}
              onValueChange={handlePriorityChange}
            >
              <SelectTrigger>
                <SelectValue placeholder={t('selectPriority')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={PRIORITY_VALUES.LOW.toString()}>{t(getPriorityText(PRIORITY_VALUES.LOW))}</SelectItem>
                <SelectItem value={PRIORITY_VALUES.MEDIUM.toString()}>{t(getPriorityText(PRIORITY_VALUES.MEDIUM))}</SelectItem>
                <SelectItem value={PRIORITY_VALUES.HIGH.toString()}>{t(getPriorityText(PRIORITY_VALUES.HIGH))}</SelectItem>
              </SelectContent>
            </Select>
            <div className="flex flex-col">
              <label htmlFor="dueDate">{t('dueDate')}</label>
              <DatePicker
                id="dueDate"
                selected={new Date(newTask.dueDate)}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className="w-full p-2 border rounded"
              />
            </div>
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type="submit">{t('createTask')}</Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default CreateTaskForm;