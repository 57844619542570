import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Subtask, NewSubtask } from '../../types/task';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Checkbox } from '../ui/checkbox';
import { Trash2, Edit, Plus, Check, X, Loader2, GripVertical, Clock } from 'lucide-react';
import { useToast } from '../ui/toast';
import { useApi } from '../../hooks/useApi';
import ReactMarkdown from 'react-markdown';

interface SubtaskListProps {
  mainTaskId: string;
}

interface SubtaskItemProps {
  subtask: Subtask;
  index: number;
  onUpdateStatus: (subtaskId: string, completed: boolean) => void;
  onUpdateSubtask: (subtask: Subtask) => void;
  onDeleteSubtask: (subtaskId: string) => void;
  updateLoading: boolean;
  deleteLoading: boolean;
}

const SubtaskItem: React.FC<SubtaskItemProps> = ({
  subtask,
  index,
  onUpdateStatus,
  onUpdateSubtask,
  onDeleteSubtask,
  updateLoading,
  deleteLoading
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedSubtask, setEditedSubtask] = useState(subtask);

  const handleEdit = () => {
    setIsEditing(true);
    setEditedSubtask(subtask);
  };

  const handleSave = () => {
    onUpdateSubtask(editedSubtask);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedSubtask(subtask);
  };

  return (
    <Draggable draggableId={subtask.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="flex flex-col bg-white p-4 rounded shadow mb-2"
        >
          <div className="flex items-center mb-2">
            <GripVertical className="text-gray-400 mr-2" />
            <Checkbox
              id={`subtask-${subtask.id}`}
              checked={subtask.status === 'done'}
              onCheckedChange={(checked) => onUpdateStatus(subtask.id, checked === true)}
              disabled={updateLoading}
            />
            {isEditing ? (
              <Input
                value={editedSubtask.title}
                onChange={(e) => setEditedSubtask({ ...editedSubtask, title: e.target.value })}
                className="flex-grow ml-2"
              />
            ) : (
              <label
                htmlFor={`subtask-${subtask.id}`}
                className={`flex-grow ml-2 ${subtask.status === 'done' ? 'line-through text-gray-500' : ''}`}
              >
                {subtask.title}
              </label>
            )}
          </div>
          {isEditing ? (
            <>
              <Textarea
                value={editedSubtask.description}
                onChange={(e) => setEditedSubtask({ ...editedSubtask, description: e.target.value })}
                placeholder={t('description')}
                className="w-full mt-2"
              />
              <Input
                type="number"
                value={editedSubtask.estimatedTime.toString()}
                onChange={(e) => setEditedSubtask({ ...editedSubtask, estimatedTime: parseInt(e.target.value) || 0 })}
                placeholder={t('estimatedTimeMin')}
                className="w-full mt-2"
              />
              <div className="flex justify-end mt-2">
                <Button size="sm" onClick={handleSave} disabled={updateLoading}>
                  {updateLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Check className="h-4 w-4" />}
                </Button>
                <Button size="sm" variant="outline" onClick={handleCancel} className="ml-2">
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </>
          ) : (
            <>
              {subtask.description && (
                <ReactMarkdown className="prose prose-sm max-w-none text-gray-600 mt-1">
                  {subtask.description}
                </ReactMarkdown>
              )}
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center text-sm text-gray-500">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>{subtask.estimatedTime} {t('min')}</span>
                </div>
                <div>
                  <Button size="sm" variant="ghost" onClick={handleEdit}>
                    <Edit className="h-4 w-4" />
                  </Button>
                  <Button 
                    size="sm" 
                    variant="ghost" 
                    onClick={() => onDeleteSubtask(subtask.id)}
                    disabled={deleteLoading}
                  >
                    {deleteLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Trash2 className="h-4 w-4" />}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  );
};

const SubtaskList: React.FC<SubtaskListProps> = ({ mainTaskId }) => {
  const { t } = useTranslation();
  const [newSubtaskTitle, setNewSubtaskTitle] = useState('');
  const [newSubtaskDescription, setNewSubtaskDescription] = useState('');
  const [newSubtaskEstimatedTime, setNewSubtaskEstimatedTime] = useState('');
  const { toast } = useToast();
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: subtasks = [], isLoading } = useQuery(
    ['subtasks', mainTaskId],
    () => api.getSubtasks(mainTaskId),
    {
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToFetchSubtasks'),
          variant: "destructive",
        });
      },
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['subtasks', mainTaskId]);
    queryClient.invalidateQueries(['tasks', mainTaskId]);
  };

  const addSubtaskMutation = useMutation(
    (newSubtask: NewSubtask) => api.createSubtask(mainTaskId, newSubtask),
    {
      onSuccess: () => {
        invalidateQueries();
        toast({
          title: t('subtaskAdded'),
          description: t('subtaskAddedSuccessfully'),
        });
        setNewSubtaskTitle('');
        setNewSubtaskDescription('');
        setNewSubtaskEstimatedTime('');
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToAddSubtask'),
          variant: "destructive",
        });
      },
    }
  );

  const updateSubtaskMutation = useMutation(
    ({ subtaskId, updates }: { subtaskId: string; updates: Partial<Subtask> }) =>
      api.updateSubtask(mainTaskId, subtaskId, updates),
    {
      onSuccess: () => {
        invalidateQueries();
        toast({
          title: t('subtaskUpdated'),
          description: t('subtaskUpdatedSuccessfully'),
        });
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToUpdateSubtask'),
          variant: "destructive",
        });
      },
    }
  );

  const deleteSubtaskMutation = useMutation(
    (subtaskId: string) => api.deleteSubtask(mainTaskId, subtaskId),
    {
      onSuccess: () => {
        invalidateQueries();
        toast({
          title: t('subtaskDeleted'),
          description: t('subtaskDeletedSuccessfully'),
        });
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToDeleteSubtask'),
          variant: "destructive",
        });
      },
    }
  );

  const reorderSubtasksMutation = useMutation(
    (newOrder: string[]) => api.reorderSubtasks(mainTaskId, newOrder),
    {
      onSuccess: () => {
        invalidateQueries();
        toast({
          title: t('subtasksReordered'),
          description: t('subtasksReorderedSuccessfully'),
        });
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToReorderSubtasks'),
          variant: "destructive",
        });
      },
    }
  );

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(subtasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newOrder = items.map(item => item.id);
    reorderSubtasksMutation.mutate(newOrder);
  };

  const handleAddSubtask = () => {
    if (newSubtaskTitle.trim()) {
      const estimatedTime = parseInt(newSubtaskEstimatedTime) || 0;
      addSubtaskMutation.mutate({
        title: newSubtaskTitle,
        description: newSubtaskDescription,
        estimatedTime: estimatedTime,
      });
    }
  };

  const handleUpdateSubtaskStatus = (subtaskId: string, completed: boolean) => {
    updateSubtaskMutation.mutate({
      subtaskId,
      updates: { status: completed ? 'done' : 'todo' },
    });
  };

  const handleUpdateSubtask = (subtask: Subtask) => {
    updateSubtaskMutation.mutate({
      subtaskId: subtask.id,
      updates: subtask,
    });
  };

  const handleDeleteSubtask = (subtaskId: string) => {
    deleteSubtaskMutation.mutate(subtaskId);
  };

  if (isLoading) {
    return <div>{t('loadingSubtasks')}</div>;
  }

  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-lg">{t('subtasks')}</h3>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="subtasks">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {subtasks.map((subtask, index) => (
                <SubtaskItem 
                  key={subtask.id}
                  subtask={subtask}
                  index={index}
                  onUpdateStatus={handleUpdateSubtaskStatus}
                  onUpdateSubtask={handleUpdateSubtask}
                  onDeleteSubtask={handleDeleteSubtask}
                  updateLoading={updateSubtaskMutation.isLoading}
                  deleteLoading={deleteSubtaskMutation.isLoading}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex flex-col space-y-2">
        <Input
          placeholder={t('newSubtask')}
          value={newSubtaskTitle}
          onChange={(e) => setNewSubtaskTitle(e.target.value)}
        />
        <Textarea
          placeholder={t('description')}
          value={newSubtaskDescription}
          onChange={(e) => setNewSubtaskDescription(e.target.value)}
        />
        <div className="flex items-center space-x-2">
          <Input
            type="number"
            placeholder={t('estimatedTimeMin')}
            value={newSubtaskEstimatedTime}
            onChange={(e) => setNewSubtaskEstimatedTime(e.target.value)}
            className="w-32"
          />
          <Button onClick={handleAddSubtask} disabled={addSubtaskMutation.isLoading || !newSubtaskTitle.trim()}>
            {addSubtaskMutation.isLoading ? <Loader2 className="h-4 w-4 animate-spin mr-2" /> : <Plus className="h-4 w-4 mr-2" />}
            {addSubtaskMutation.isLoading ? t('adding') : t('add')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SubtaskList);