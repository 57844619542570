import axios, { AxiosInstance } from 'axios';
import { Task, Subtask, NewSubtask } from '../types/task';
import { AssistantType } from '../types/assistant';

const API_BASE_URL = 'https://banban.tech';

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // 这将包含凭证（cookies）
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    console.log('Request:', config.method, config.url, config.data);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_BASE_URL}/api/refresh-token`, { refreshToken });
        const { token } = response.data;
        localStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return api(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearAuthToken = () => {
  delete api.defaults.headers.common['Authorization'];
};

// Authentication
export const login = async (email: string, password: string) => {
  const response = await api.post('/api/login', { email, password });
  return response.data;
};

export const register = async (username: string, email: string, password: string) => {
  const response = await api.post('/api/register', { username, email, password });
  return response.data;
};

export const logout = async () => {
  const response = await api.post('/api/logout');
  return response.data;
};

// User
export const getCurrentUser = async () => {
  const response = await api.get('/api/user');
  return response.data;
};

// Tasks
export const getTasks = async (): Promise<Task[]> => {
  const response = await api.get('/api/tasks');
  return response.data;
};

export const getTask = async (id: string): Promise<Task> => {
  const response = await api.get(`/api/tasks/${id}`);
  return response.data;
};

export const createTask = async (task: Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'subtasks' | 'userId'>): Promise<Task> => {
  try {
    console.log('Sending task data:', JSON.stringify(task, null, 2));
    const response = await api.post('/api/tasks', task);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in createTask:', error);
    if (axios.isAxiosError(error) && error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
      throw new Error(error.response.data.error || 'Failed to create task');
    }
    throw error;
  }
};

export const updateTask = async (taskId: string, updates: Partial<Task>): Promise<Task> => {
  const response = await api.put(`/api/tasks/${taskId}`, updates);
  return response.data;
};

export const deleteTask = async (id: string): Promise<void> => {
  await api.delete(`/api/tasks/${id}`);
};

// Subtasks
export const getSubtasks = async (taskId: string): Promise<Subtask[]> => {
  const response = await api.get(`/api/tasks/${taskId}/subtasks`);
  // 可以添加一些验证逻辑here
  return response.data as Subtask[];
};

export const createSubtask = async (taskId: string, subtask: NewSubtask): Promise<Subtask> => {
  const response = await api.post(`/api/tasks/${taskId}/subtasks`, subtask);
  // 可以添加一些验证逻辑here
  return response.data as Subtask;
};

export const updateSubtask = async (taskId: string, subtaskId: string, updates: Partial<Subtask>): Promise<Subtask> => {
  const response = await api.put(`/api/tasks/${taskId}/subtasks/${subtaskId}`, updates);
  return response.data;
};

export const deleteSubtask = async (taskId: string, subtaskId: string): Promise<void> => {
  await api.delete(`/api/tasks/${taskId}/subtasks/${subtaskId}`);
};

export const reorderSubtasks = async (taskId: string, newOrder: string[]): Promise<void> => {
  try {
    await api.put(`/api/tasks/${taskId}/subtasks/reorder`, { newOrder });
    console.log('Reorder request sent:', { taskId, newOrder }); // 添加这行来记录发送的数据
  } catch (error) {
    console.error('Error in reorderSubtasks:', error);
    throw error;
  }
};

// AI-assisted features
export const generateSubtasks = async (taskId: string): Promise<void> => {
  await api.post(`/api/tasks/${taskId}/generate-subtasks`);
};


export const generateDemo = async (taskId: string): Promise<string> => {
  const response = await api.post(`/api/tasks/${taskId}/generate-demo`);
  return response.data.demo;
};

export const generateGuidance = async (taskId: string): Promise<string> => {
  const response = await api.post(`/api/tasks/${taskId}/generate-guidance`);
  return response.data.guidance;
};

// Task management
export const updateTaskStatus = async (taskId: string, status: string): Promise<void> => {
  await api.put(`/api/tasks/${taskId}/status`, { status });
};

export const getTaskProgress = async (taskId: string): Promise<number> => {
  const response = await api.get(`/api/tasks/${taskId}/progress`);
  return response.data.progress;
};

export const setTaskReminder = async (taskId: string, reminderTime: Date): Promise<void> => {
  await api.post(`/api/tasks/${taskId}/reminder`, { reminderTime });
};

// User task stats
export const getUserTaskStats = async (): Promise<any> => {
  const response = await api.get('/api/user/task-stats');
  return response.data;
};

// Search
export const searchTasks = async (query: string): Promise<Task[]> => {
  const response = await api.get('/api/search/tasks', { params: { q: query } });
  return response.data;
};

// Task analytics
export const getTaskAnalytics = async (startDate: string, endDate: string): Promise<any> => {
  const response = await api.get('/api/tasks/analytics', { params: { startDate, endDate } });
  return response.data;
};

// Export and Import
export const exportUserTasks = async (format: 'json' | 'csv'): Promise<Blob> => {
  const response = await api.get('/api/tasks/export', { 
    params: { format },
    responseType: 'blob'
  });
  return response.data;
};

export const importUserTasks = async (file: File, format: 'json' | 'csv'): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('format', format);
  await api.post('/api/tasks/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// Batch operations
export const batchUpdateTaskStatus = async (taskIds: string[], status: string): Promise<void> => {
  await api.put('/api/tasks/batch-update-status', { taskIds, status });
};


export const updateTaskOrderAndStatus = async (taskId: string, newStatus: string, prevOrder: string, nextOrder: string): Promise<void> => {
  console.log('Calling updateTaskOrderAndStatus API:', { taskId, newStatus, prevOrder, nextOrder });
  try {
    const response = await api.put(`/api/tasks/${taskId}/order-and-status`, { newStatus, prevOrder, nextOrder });
    console.log('API response:', response.data);
  } catch (error) {
    console.error('Error updating task order and status:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      throw new Error(error.response?.data?.error || error.message);
    }
    throw error;
  }
};

export const getTaskTimeStats = async (): Promise<{ totalTime: number, completedTime: number }> => {
  const response = await api.get('/api/tasks/time-stats');
  return response.data;
};

export const generateDailySummary = async (tasks: Task[], assistantType: AssistantType): Promise<{summary: string, audioBlob: Blob, createdAt: string}> => {
  try {
    const response = await api.post('/api/ai-assistant/daily-message', 
      { tasks, assistantType },
      { responseType: 'blob' }
    );
    
    return {
      summary: response.headers['x-summary'] as string,
      createdAt: response.headers['x-created-at'] as string,
      audioBlob: new Blob([response.data], { type: 'audio/mpeg' })
    };
  } catch (error) {
    console.error('Error generating daily summary:', error);
    throw error;
  }
};

export default api;