// src/hooks/useApi.ts

import { useCallback } from 'react';
import * as apiService from '../services/api';
import { Task, Subtask, NewSubtask } from '../types/task';
import { AssistantType } from '@/types/assistant';

export const useApi = () => {
  const getTasks = useCallback(async (): Promise<Task[]> => {
    try {
      return await apiService.getTasks();
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  }, []);

  const getTask = useCallback(async (taskId: string): Promise<Task> => {
    try {
      return await apiService.getTask(taskId);
    } catch (error) {
      console.error('Error fetching task:', error);
      throw error;
    }
  }, []);

  const createTask = useCallback(async (task: Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'subtasks'>): Promise<Task> => {
    try {
      return await apiService.createTask(task);
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  }, []);

  const updateTask = useCallback(async (taskId: string, updates: Partial<Task>): Promise<Task> => {
    try {
      return await apiService.updateTask(taskId, updates);
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  }, []);

  const deleteTask = useCallback(async (taskId: string): Promise<void> => {
    try {
      await apiService.deleteTask(taskId);
    } catch (error) {
      console.error('Error deleting task:', error);
      throw error;
    }
  }, []);

  const getSubtasks = useCallback(async (taskId: string): Promise<Subtask[]> => {
    try {
      return await apiService.getSubtasks(taskId);
    } catch (error) {
      console.error('Error fetching subtasks:', error);
      throw error;
    }
  }, []);

  const createSubtask = useCallback(async (taskId: string, subtask: NewSubtask): Promise<Subtask> => {
    try {
      return await apiService.createSubtask(taskId, subtask);
    } catch (error) {
      console.error('Error creating subtask:', error);
      throw error;
    }
  }, []);

  const updateSubtask = useCallback(async (taskId: string, subtaskId: string, updates: Partial<Subtask>): Promise<Subtask> => {
    try {
      return await apiService.updateSubtask(taskId, subtaskId, updates);
    } catch (error) {
      console.error('Error updating subtask:', error);
      throw error;
    }
  }, []);

  const deleteSubtask = useCallback(async (taskId: string, subtaskId: string): Promise<void> => {
    try {
      await apiService.deleteSubtask(taskId, subtaskId);
    } catch (error) {
      console.error('Error deleting subtask:', error);
      throw error;
    }
  }, []);

  const reorderSubtasks = useCallback(async (taskId: string, newOrder: string[]): Promise<void> => {
    try {
      await apiService.reorderSubtasks(taskId, newOrder);
    } catch (error) {
      console.error('Error reordering subtasks:', error);
      throw error;
    }
  }, []);

  const generateSubtasks = useCallback(async (taskId: string): Promise<void> => {
    try {
      await apiService.generateSubtasks(taskId);
    } catch (error) {
      console.error('Error generating subtasks:', error);
      throw error;
    }
  }, []);

  const generateDemo = useCallback(async (taskId: string): Promise<string> => {
    try {
      return await apiService.generateDemo(taskId);
    } catch (error) {
      console.error('Error generating demo:', error);
      throw error;
    }
  }, []);

  const generateGuidance = useCallback(async (taskId: string): Promise<string> => {
    try {
      return await apiService.generateGuidance(taskId);
    } catch (error) {
      console.error('Error generating guidance:', error);
      throw error;
    }
  }, []);

  const getUserTaskStats = useCallback(async (): Promise<any> => {
    try {
      return await apiService.getUserTaskStats();
    } catch (error) {
      console.error('Error fetching user task stats:', error);
      throw error;
    }
  }, []);

  const getTaskAnalytics = useCallback(async (startDate: string, endDate: string): Promise<any> => {
    try {
      return await apiService.getTaskAnalytics(startDate, endDate);
    } catch (error) {
      console.error('Error fetching task analytics:', error);
      throw error;
    }
  }, []);

  

  const updateTaskOrderAndStatus = useCallback(async (taskId: string, newStatus: string, prevOrder: string, nextOrder: string): Promise<void> => {
    try {
      await apiService.updateTaskOrderAndStatus(taskId, newStatus, prevOrder, nextOrder);
    } catch (error) {
      console.error('Error updating task order and status:', error);
      throw error;
    }
  }, []);

  const getTaskTimeStats = useCallback(async (): Promise<{ totalTime: number, completedTime: number }> => {
    try {
      return await apiService.getTaskTimeStats();
    } catch (error) {
      console.error('Error fetching task time stats:', error);
      throw error;
    }
  }, []);

  const generateDailySummary = useCallback(async (tasks: Task[], assistantType: AssistantType): Promise<{summary: string, audioBlob: Blob, createdAt: string}> => {
    try {
      return await apiService.generateDailySummary(tasks, assistantType);
    } catch (error) {
      console.error('Error generating daily summary:', error);
      throw error;
    }
  }, []);

  return {
    getTasks,
    getTask,
    createTask,
    updateTask,
    deleteTask,
    getSubtasks,
    createSubtask,
    updateSubtask,
    deleteSubtask,
    reorderSubtasks,
    generateSubtasks,
    generateDemo,
    generateGuidance,
    getUserTaskStats,
    getTaskAnalytics,
    updateTaskOrderAndStatus,
    getTaskTimeStats,
    generateDailySummary,
  };
};

export type ApiHook = ReturnType<typeof useApi>;