import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeToggle from './ThemeToggle';
import { Button } from '../ui/button';
import { APP_CONFIG } from '../../config';
import logo from '../../logo.png';
import { Menu, X, Home, List, LogOut } from 'lucide-react';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-background to-secondary">
      <nav className="bg-secondary shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img className="h-8 w-auto" src={logo} alt={APP_CONFIG.name} />
                <span className="ml-2 text-lg font-semibold text-foreground">{APP_CONFIG.name}</span>
              </Link>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link 
                  to="/" 
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActive('/') ? 'border-primary text-primary' : 'border-transparent text-foreground hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  {t('dashboard')}
                </Link>
                <Link 
                  to="/tasks" 
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActive('/tasks') ? 'border-primary text-primary' : 'border-transparent text-foreground hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  {t('taskManagement')}
                </Link>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-4">
              <ThemeToggle />
              <LanguageSwitcher />
              {user && (
                <Button onClick={handleLogout} variant="outline" className="text-foreground hover:text-accent">
                  {t('logout')}
                </Button>
              )}
            </div>
            <div className="sm:hidden flex items-center">
              <Button variant="ghost" size="icon" onClick={toggleMenu}>
                {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </Button>
            </div>
          </div>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <Link 
              to="/" 
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/') ? 'bg-primary text-primary-foreground' : 'text-foreground hover:bg-gray-50 hover:text-gray-900'
              }`}
              onClick={toggleMenu}
            >
              {t('dashboard')}
            </Link>
            <Link 
              to="/tasks" 
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/tasks') ? 'bg-primary text-primary-foreground' : 'text-foreground hover:bg-gray-50 hover:text-gray-900'
              }`}
              onClick={toggleMenu}
            >
              {t('taskManagement')}
            </Link>
            <div className="px-3 py-2">
              <ThemeToggle />
            </div>
            <div className="px-3 py-2">
              <LanguageSwitcher />
            </div>
            {user && (
              <Button onClick={handleLogout} variant="outline" className="w-full text-foreground hover:text-accent">
                {t('logout')}
              </Button>
            )}
          </div>
        </div>
      )}

      <main className="flex-grow py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>

      <footer className="bg-secondary py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm text-muted-foreground">
            {APP_CONFIG.copyright} | {t('contactUs')}: <a href={`mailto:${APP_CONFIG.contactEmail}`} className="hover:underline">{APP_CONFIG.contactEmail}</a>
          </p>
        </div>
      </footer>

      {user && (
        <nav className="sm:hidden fixed bottom-0 left-0 right-0 bg-secondary border-t">
          <div className="flex justify-around py-2">
            <Link to="/" className={`flex flex-col items-center ${isActive('/') ? 'text-primary' : 'text-foreground'} hover:text-accent`}>
              <Home className="h-6 w-6" />
              <span className="text-xs">{t('dashboard')}</span>
            </Link>
            <Link to="/tasks" className={`flex flex-col items-center ${isActive('/tasks') ? 'text-primary' : 'text-foreground'} hover:text-accent`}>
              <List className="h-6 w-6" />
              <span className="text-xs">{t('tasks')}</span>
            </Link>
            <button onClick={handleLogout} className="flex flex-col items-center text-foreground hover:text-accent">
              <LogOut className="h-6 w-6" />
              <span className="text-xs">{t('logout')}</span>
            </button>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Layout;