import i18next from 'i18next';

export const PRIORITY_VALUES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
} as const;

export const getPriorityText = (priority: number): string => {
  switch (priority) {
    case PRIORITY_VALUES.LOW:
      return 'low';
    case PRIORITY_VALUES.MEDIUM:
      return 'medium';
    case PRIORITY_VALUES.HIGH:
      return 'high';
    default:
      return 'unknown';
  }
};

export const getPriorityColor = (priority: number): string => {
  switch (priority) {
    case PRIORITY_VALUES.LOW:
      return 'bg-green-200 text-green-800';
    case PRIORITY_VALUES.MEDIUM:
      return 'bg-yellow-200 text-yellow-800';
    case PRIORITY_VALUES.HIGH:
      return 'bg-red-200 text-red-800';
    default:
      return 'bg-gray-200 text-gray-800';
  }
};

// 这个函数可以在需要翻译的地方使用
export const getTranslatedPriorityText = (priority: number): string => {
  return i18next.t(getPriorityText(priority));
};