// src/types/assistant.ts
export type AssistantType = '优' | '凌' | '点点';

export interface AssistantPersonality {
  name: AssistantType;
  traits: string[];
  speakingStyle: string;
  referenceId: string;
}

export const assistantPersonalities: Record<AssistantType, AssistantPersonality> = {
  '优': {
    name: '优',
    traits: ['成熟稳重', '体贴入微', '博学多才'],
    speakingStyle: '低沉柔和的声线,语速适中,富有感情',
    referenceId: '8ea3849152ec4e48b9b531b0c68b6ffe'
  },
  '凌': {
    name: '凌',
    traits: ['沉默寡言', '行事理性', '隐藏深层情感'],
    speakingStyle: '轻柔平静的声线,语速缓慢,话语简短但一针见血',
    referenceId: 'your_ling_reference_id'
  },
  '点点': {
    name: '点点',
    traits: ['慵懒可爱', '善于调节气氛', '重视舒适和享受'],
    speakingStyle: '软糯慵懒的声线,语速较慢,经常使用可爱的语气词',
    referenceId: 'your_diandian_reference_id'
  }
};