import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Task } from '../types/task';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Plus, Search } from 'lucide-react';
import TaskCard from '../components/function/TaskCard';
import CreateTaskForm from '../components/function/CreateTaskForm';
import { useApi } from '../hooks/useApi';
import { useToast } from '../components/ui/toast';
import { webSocketService, WebSocketMessage } from '../services/WebSocketService';
import VirtualAssistant from '../components/function/VirtualAssistant';

const TaskManagementPage: React.FC = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();
  const api = useApi();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: tasks = [], isLoading } = useQuery('tasks', api.getTasks, {
    onError: () => {
      toast({
        title: t('error'),
        description: t('failedToFetchTasks'),
        variant: "destructive",
      });
    },
  });

  const createTaskMutation = useMutation(api.createTask, {
    onSuccess: () => {
      queryClient.invalidateQueries('tasks');
      toast({
        title: t('taskCreated'),
        description: t('taskCreatedSuccessfully'),
      });
      setIsCreateModalOpen(false);
    },
    onError: () => {
      toast({
        title: t('error'),
        description: t('failedToCreateTask'),
        variant: "destructive",
      });
    },
  });

  const updateTaskMutation = useMutation(
    ({ taskId, updates }: { taskId: string; updates: Partial<Task> }) => 
      api.updateTask(taskId, updates),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast({
          title: t('taskUpdated'),
          description: t('taskUpdatedSuccessfully'),
        });
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToUpdateTask'),
          variant: "destructive",
        });
      },
    }
  );

  const deleteTaskMutation = useMutation(api.deleteTask, {
    onSuccess: () => {
      queryClient.invalidateQueries('tasks');
      toast({
        title: t('taskDeleted'),
        description: t('taskDeletedSuccessfully'),
      });
    },
    onError: () => {
      toast({
        title: t('error'),
        description: t('failedToDeleteTask'),
        variant: "destructive",
      });
    },
  });

  const updateTaskOrderMutation = useMutation(
    ({ taskId, newStatus, prevOrder, nextOrder }: { taskId: string; newStatus: string; prevOrder: string; nextOrder: string }) =>
      api.updateTaskOrderAndStatus(taskId, newStatus, prevOrder, nextOrder),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast({
          title: t('taskOrderUpdated'),
          description: t('taskOrderUpdatedSuccessfully'),
        });
      },
      onError: () => {
        toast({
          title: t('error'),
          description: t('failedToUpdateTaskOrder'),
          variant: "destructive",
        });
      },
    }
  );

  const handleCreateTask = useCallback(async (newTaskData: Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'subtasks' | 'userId'>): Promise<void> => {
    if (!user) {
      toast({
        title: t('error'),
        description: t('userNotAuthenticated'),
        variant: "destructive",
      });
      return;
    }
    try {
      console.log('Attempting to create task:', newTaskData);
      await createTaskMutation.mutateAsync({
        ...newTaskData,
        userId: user.id,
      });
    } catch (error) {
      console.error('Failed to create task:', error);
      if (error instanceof Error) {
        toast({
          title: t('error'),
          description: error.message,
          variant: "destructive",
        });
      } else {
        toast({
          title: t('error'),
          description: t('unknownError'),
          variant: "destructive",
        });
      }
    }
  }, [createTaskMutation, t, user, toast]);

  const handleUpdateTask = useCallback((taskId: string, updates: Partial<Task>) => {
    updateTaskMutation.mutate({ taskId, updates });
  }, [updateTaskMutation]);

  const handleDeleteTask = useCallback((taskId: string) => {
    deleteTaskMutation.mutate(taskId);
  }, [deleteTaskMutation]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newStatus = destination.droppableId as Task['status'];
    const tasksInNewStatus = tasks.filter(task => task.status === newStatus)
      .sort((a, b) => a.order.localeCompare(b.order));
    
    let prevOrder = 'START';
    let nextOrder = 'END';

    if (destination.index > 0) {
      prevOrder = tasksInNewStatus[destination.index - 1].order;
    }
    
    if (destination.index < tasksInNewStatus.length) {
      nextOrder = tasksInNewStatus[destination.index].order;
    }

    try {
      await updateTaskOrderMutation.mutateAsync({ 
        taskId: draggableId, 
        newStatus, 
        prevOrder, 
        nextOrder 
      });
    } catch (error) {
      console.error(t('failedToUpdateTask'), error);
    }
  }, [tasks, updateTaskOrderMutation, t]);

  const sortedAndFilteredTasks = useMemo(() => {
    const filteredTasks = tasks.filter(task =>
      task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const tasksByStatus = {
      todo: [] as Task[],
      in_progress: [] as Task[],
      done: [] as Task[]
    };

    filteredTasks.forEach(task => {
      if (tasksByStatus[task.status]) {
        tasksByStatus[task.status].push(task);
      }
    });

    for (const status in tasksByStatus) {
      tasksByStatus[status as keyof typeof tasksByStatus].sort((a, b) => a.order.localeCompare(b.order));
    }

    return tasksByStatus;
  }, [tasks, searchTerm]);

  useEffect(() => {
    const handleWebSocketMessage = (message: WebSocketMessage) => {
      console.log(t('receivedWebSocketMessage'), message);
      switch (message.Type) {
        case "task_updated":
        case "task_created":
        case "task_deleted":
        case "subtask_created":
        case "subtask_updated":
        case "subtask_deleted":
        case "subtasks_reordered":
          queryClient.invalidateQueries('tasks');
          break;
        default:
          console.warn('Unhandled WebSocket message type:', message.Type);
      }
    };

    webSocketService.addMessageHandler(handleWebSocketMessage);

    return () => {
      webSocketService.removeMessageHandler(handleWebSocketMessage);
    };
  }, [queryClient, t]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{t('taskManagement')}</h1>
        <Button onClick={() => setIsCreateModalOpen(true)} disabled={isLoading}>
          <Plus className="mr-2 h-4 w-4" /> {t('newTask')}
        </Button>
      </div>

      <VirtualAssistant tasks={tasks} />

      <div className="mb-4 relative">
        <Input
          type="text"
          placeholder={t('searchTasks')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10"
          disabled={isLoading}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>

      {isLoading ? (
        <div className="text-center">{t('loadingTasks')}</div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {(['todo', 'in_progress', 'done'] as Task['status'][]).map(status => (
              <Droppable key={status} droppableId={status}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`bg-secondary p-4 rounded-lg min-h-[200px] ${snapshot.isDraggingOver ? 'bg-accent bg-opacity-10' : ''}`}
                  >
                    <h2 className="font-bold mb-4 capitalize">{t(status.replace('_', ''))}</h2>
                    {sortedAndFilteredTasks[status].map((task: Task, index: number) => (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`mb-2 ${snapshot.isDragging ? 'opacity-50' : ''}`}
                          >
                            <TaskCard
                              task={task}
                              onUpdateTask={handleUpdateTask}
                              onDeleteTask={handleDeleteTask}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      )}

      {isCreateModalOpen && (
        <CreateTaskForm
          onCreateTask={handleCreateTask}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
    </div>
  );
};

export default React.memo(TaskManagementPage);