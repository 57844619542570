import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Task } from '../../types/task';
import { Button } from '../ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Badge } from '../ui/badge';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Calendar, Clock, CheckCircle, Circle, ArrowRight, ChevronDown, ChevronUp, Trash2, Edit, FileText, MessageSquare, Sparkles } from 'lucide-react';
import { format } from 'date-fns';
import { useApi } from '../../hooks/useApi';
import { useToast } from '../ui/toast';
import { PRIORITY_VALUES, getPriorityText, getPriorityColor } from '../../utils/taskUtils';
import SubtaskList from './SubtaskList';
import { webSocketService, WebSocketMessage } from '../../services/WebSocketService';
import ReactMarkdown from 'react-markdown';

interface TaskCardProps {
  task: Task;
  onUpdateTask: (taskId: string, updates: Partial<Task>) => void;
  onDeleteTask: (taskId: string) => void;
}

interface TimeInfo {
  estimatedTime: number;
  remainingTime: number;
  completionPercentage: number;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, onUpdateTask, onDeleteTask }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState(task);
  const [localTask, setLocalTask] = useState(task);
  const [timeInfo, setTimeInfo] = useState<TimeInfo>({
    estimatedTime: task.estimatedTime,
    remainingTime: task.remainingTime,
    completionPercentage: task.completionPercentage
  });
  
  const api = useApi();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const updateTaskMutation = useMutation(
    (updates: Partial<Task>) => api.updateTask(task.id, updates),
    {
      onSuccess: (updatedTask) => {
        onUpdateTask(task.id, updatedTask);
        setLocalTask(prevTask => ({ ...prevTask, ...updatedTask }));
        setTimeInfo({
          estimatedTime: updatedTask.estimatedTime,
          remainingTime: updatedTask.remainingTime,
          completionPercentage: updatedTask.completionPercentage
        });
        queryClient.invalidateQueries(['tasks', task.id]);
        toast({ title: t('taskUpdated'), description: t('taskUpdatedSuccessfully') });
      },
      onError: (error) => {
        console.error('Error updating task:', error);
        toast({ title: t('error'), description: t('failedToUpdateTask'), variant: "destructive" });
      },
    }
  );

  const generateSubtasksMutation = useMutation(
    () => api.generateSubtasks(task.id),
    {
      onSuccess: () => {
        toast({ 
          title: t('subtasksGenerated'), 
          description: t('subtasksGeneratedSuccessfully') 
        });
        queryClient.invalidateQueries(['subtasks', task.id]);
      },
      onError: (error) => {
        console.error('Failed to generate subtasks:', error);
        toast({ 
          title: t('error'), 
          description: t('failedToGenerateSubtasks'), 
          variant: "destructive" 
        });
      },
    }
  );

  const generateDemoMutation = useMutation(
    () => api.generateDemo(task.id),
    {
      onSuccess: (demo) => {
        updateTaskMutation.mutate({ demo });
      },
      onError: (error) => {
        console.error('Error generating demo:', error);
        toast({ title: t('error'), description: t('failedToGenerateDemo'), variant: "destructive" });
      },
    }
  );

  const generateGuidanceMutation = useMutation(
    () => api.generateGuidance(task.id),
    {
      onSuccess: (guidance) => {
        updateTaskMutation.mutate({ guidance });
      },
      onError: (error) => {
        console.error('Error generating guidance:', error);
        toast({ title: t('error'), description: t('failedToGenerateGuidance'), variant: "destructive" });
      },
    }
  );

  const sanitizeInput = (input: string): string => {
    return input.replace(/<[^>]*>?/gm, '');
  };

  const handleSave = useCallback(() => {
    const sanitizedTitle = sanitizeInput(editedTask.title);
    const sanitizedDescription = sanitizeInput(editedTask.description);
    
    if (sanitizedTitle !== editedTask.title || sanitizedDescription !== editedTask.description) {
      toast({
        title: t('warning'),
        description: t('potentiallyUnsafeContentRemoved'),
        //variant: "warning",
      });
    }

    const updatedTask = {
      ...editedTask,
      title: sanitizedTitle,
      description: sanitizedDescription,
    };

    updateTaskMutation.mutate(updatedTask);
    setIsEditing(false);
  }, [editedTask, updateTaskMutation, toast, t]);

  const handleStatusChange = useCallback(() => {
    const statusOrder: Task['status'][] = ['todo', 'in_progress', 'done'];
    const currentIndex = statusOrder.indexOf(localTask.status);
    const newStatus = statusOrder[(currentIndex + 1) % statusOrder.length];
    updateTaskMutation.mutate({ status: newStatus });
  }, [localTask.status, updateTaskMutation]);

  const handlePriorityChange = (value: string) => {
    setEditedTask(prev => ({ ...prev, priority: Number(value) }));
  };

  const getStatusIcon = useMemo(() => {
    switch (localTask.status) {
      case 'todo': return <Circle className="h-4 w-4 text-gray-500" />;
      case 'in_progress': return <ArrowRight className="h-4 w-4 text-blue-500" />;
      case 'done': return <CheckCircle className="h-4 w-4 text-green-500" />;
      default: return null;
    }
  }, [localTask.status]);

  const handleWebSocketMessage = useCallback((message: WebSocketMessage) => {
    console.log('Received WebSocket message:', message);
    if (message.Type === 'task_times_updated' && message.Content.taskId === task.id) {
      setTimeInfo({
        estimatedTime: message.Content.estimatedTime,
        remainingTime: message.Content.remainingTime,
        completionPercentage: message.Content.completionPercentage,
      });
    } else if (message.Type === 'task_updated' && message.Content.taskId === task.id) {
      setLocalTask(prevTask => ({
        ...prevTask,
        ...message.Content,
      }));
      setTimeInfo({
        estimatedTime: message.Content.estimatedTime,
        remainingTime: message.Content.remainingTime,
        completionPercentage: message.Content.completionPercentage,
      });
    } else if (message.Type === 'demo_generated' && message.Content.taskId === task.id) {
      setLocalTask(prevTask => ({ ...prevTask, demo: message.Content.demo }));
    } else if (message.Type === 'guidance_generated' && message.Content.taskId === task.id) {
      setLocalTask(prevTask => ({ ...prevTask, guidance: message.Content.guidance }));
    }
  }, [task.id]);

  useEffect(() => {
    webSocketService.addMessageHandler(handleWebSocketMessage);

    return () => {
      webSocketService.removeMessageHandler(handleWebSocketMessage);
    };
  }, [handleWebSocketMessage]);

  useEffect(() => {
    setLocalTask(task);
    setEditedTask(task);
    setTimeInfo({
      estimatedTime: task.estimatedTime,
      remainingTime: task.remainingTime,
      completionPercentage: task.completionPercentage
    });
  }, [task]);

  const TimeInfoComponent = React.memo(({ timeInfo }: { timeInfo: TimeInfo }) => (
    <div className="flex flex-wrap items-center text-xs text-gray-500 mt-2">
      <div className="flex items-center mr-4">
        <Clock className="h-3 w-3 mr-1" />
        <span>{t('estimatedTime')}: {timeInfo.estimatedTime} {t('mins')}</span>
      </div>
      <div className="flex items-center mr-4">
        <Clock className="h-3 w-3 mr-1" />
        <span>{t('remainingTime')}: {timeInfo.remainingTime} {t('mins')}</span>
      </div>
      <div className="flex items-center">
        <CheckCircle className="h-3 w-3 mr-1" />
        <span>{t('completion')}: {timeInfo.completionPercentage}%</span>
      </div>
    </div>
  ));

  return (
    <Card className="w-full mb-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
      <CardHeader className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-center justify-between">
          <CardTitle className="flex items-center">
            <div className="cursor-pointer" onClick={(e) => { e.stopPropagation(); handleStatusChange(); }}>
              {getStatusIcon}
            </div>
            <span className="ml-2 truncate">{localTask.title}</span>
          </CardTitle>
          <div className="flex items-center space-x-2">
            <Badge className={getPriorityColor(localTask.priority)}>
              {t(getPriorityText(localTask.priority))}
            </Badge>
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </div>
        </div>
        <TimeInfoComponent timeInfo={timeInfo} />
        <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
          <div 
            className="bg-blue-600 h-1.5 rounded-full" 
            style={{ width: `${timeInfo.completionPercentage}%` }}
          ></div>
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent>
          {isEditing ? (
            <div className="space-y-4">
              <Input
                value={editedTask.title}
                onChange={(e) => setEditedTask({ ...editedTask, title: e.target.value })}
                placeholder={t('taskTitle')}
                maxLength={255}
              />
              <Textarea
                value={editedTask.description}
                onChange={(e) => setEditedTask({ ...editedTask, description: e.target.value })}
                placeholder={t('taskDescription')}
                maxLength={1000}
              />
              <Select value={editedTask.priority.toString()} onValueChange={handlePriorityChange}>
                <SelectTrigger>
                  <SelectValue placeholder={t('selectPriority')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={PRIORITY_VALUES.LOW.toString()}>{t(getPriorityText(PRIORITY_VALUES.LOW))}</SelectItem>
                  <SelectItem value={PRIORITY_VALUES.MEDIUM.toString()}>{t(getPriorityText(PRIORITY_VALUES.MEDIUM))}</SelectItem>
                  <SelectItem value={PRIORITY_VALUES.HIGH.toString()}>{t(getPriorityText(PRIORITY_VALUES.HIGH))}</SelectItem>
                </SelectContent>
              </Select>
              <Input
                type="date"
                value={format(new Date(editedTask.dueDate), 'yyyy-MM-dd')}
                onChange={(e) => setEditedTask({ ...editedTask, dueDate: new Date(e.target.value).toISOString() })}
              />
              <div className="flex justify-end space-x-2">
                <Button onClick={() => setIsEditing(false)} variant="outline">{t('cancel')}</Button>
                <Button onClick={handleSave}>{t('save')}</Button>
              </div>
            </div>
          ) : (
            <>
              <div 
                className="text-sm text-gray-600 mb-4"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(localTask.description) }}
              />
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="h-4 w-4 mr-1" />
                  <span>{t('due')}: {format(new Date(localTask.dueDate), 'PPP')}</span>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mb-4">
                <Button size="sm" onClick={() => generateSubtasksMutation.mutate()} disabled={generateSubtasksMutation.isLoading}>
                  <Sparkles className="h-4 w-4 mr-1" /> {generateSubtasksMutation.isLoading ? t('generating') : t('generateSubtasks')}
                </Button>
                <Button size="sm" onClick={() => generateDemoMutation.mutate()} disabled={generateDemoMutation.isLoading}>
                  <FileText className="h-4 w-4 mr-1" /> {generateDemoMutation.isLoading ? t('generating') : t('generateDemo')}
                </Button>
                <Button size="sm" onClick={() => generateGuidanceMutation.mutate()} disabled={generateGuidanceMutation.isLoading}>
                  <MessageSquare className="h-4 w-4 mr-1" /> {generateGuidanceMutation.isLoading ? t('generating') : t('generateGuidance')}
                </Button>
              </div>
              <SubtaskList mainTaskId={localTask.id} />
              {localTask.demo && (
                <div className="mt-4 bg-gray-100 p-4 rounded-lg">
                  <h4 className="font-semibold text-lg mb-2">{t('demo')}:</h4>
                  <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                    {localTask.demo}
                  </ReactMarkdown>
                </div>
              )}
              {localTask.guidance && (
                <div className="mt-4 bg-gray-100 p-4 rounded-lg">
                  <h4 className="font-semibold text-lg mb-2">{t('guidance')}:</h4>
                  <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                    {localTask.guidance}
                  </ReactMarkdown>
                </div>
              )}
            </>
          )}
        </CardContent>
      )}
      {isExpanded && (
        <CardFooter className="flex justify-end items-center">
          <div className="flex space-x-2">
            <Button variant="outline" size="sm" onClick={() => setIsEditing(!isEditing)}>
              <Edit className="h-4 w-4 mr-1" /> {isEditing ? t('cancel') : t('edit')}
            </Button>
            <Button variant="destructive" size="sm" onClick={() => onDeleteTask(localTask.id)}>
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};

export default React.memo(TaskCard);