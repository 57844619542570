import React from 'react';

interface AvatarProps {
  src?: string;
  alt?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ src, alt }) => {
  return (
    <div className="w-10 h-10 rounded-full overflow-hidden">
      {src ? (
        <img src={src} alt={alt} className="w-full h-full object-cover" />
      ) : (
        <div className="w-full h-full bg-gray-300 flex items-center justify-center text-gray-600">
          {alt ? alt[0].toUpperCase() : 'A'}
        </div>
      )}
    </div>
  );
};