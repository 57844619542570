import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Avatar } from '../ui/Avatar';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { AssistantType, assistantPersonalities } from '../../types/assistant';
import { Task } from '../../types/task';
import { useApi } from '../../hooks/useApi';
import { Loader2, RefreshCw, Play, Pause, Info } from 'lucide-react';
import { cn } from "../../lib/utils";
import { format } from 'date-fns';

interface VirtualAssistantProps {
  tasks: Task[];
}

const VirtualAssistant: React.FC<VirtualAssistantProps> = ({ tasks }) => {
  const { t } = useTranslation();
  const [assistantType, setAssistantType] = useState<AssistantType>('优');
  const [summary, setSummary] = useState('');
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [createdAt, setCreatedAt] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const api = useApi();
  const audioRef = useRef<HTMLAudioElement>(null);

  const generateDailySummary = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await api.generateDailySummary(tasks, assistantType);
      console.log('Daily summary result:', result);
      setSummary(result.summary);
      setCreatedAt(result.createdAt);
      
      const url = URL.createObjectURL(result.audioBlob);
      setAudioUrl(url);
    } catch (error) {
      console.error('Failed to generate summary:', error);
    } finally {
      setIsLoading(false);
    }
  }, [api, tasks, assistantType]);

  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Card className="mb-6 overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 py-4 px-6 bg-gradient-to-r from-primary to-primary-foreground text-primary-foreground">
        <CardTitle className="text-2xl font-bold">{t('aiAssistant')}</CardTitle>
        <Select value={assistantType} onValueChange={(value: AssistantType) => setAssistantType(value)}>
          <SelectTrigger className="w-[180px] bg-white text-primary border-primary-foreground">
            <SelectValue placeholder={t('selectAssistant')} />
          </SelectTrigger>
          <SelectContent>
            {Object.keys(assistantPersonalities).map((type) => (
              <SelectItem key={type} value={type as AssistantType} className="text-foreground">
                <div className="flex items-center">
                  <div className="w-8 h-8 min-w-[2rem] mr-3 overflow-hidden rounded-full">
                    <Avatar src={`/avatars/${type}.png`} alt={type} />
                  </div>
                  <span className="truncate">{t(`assistant${type}Name`)}</span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="mt-4 px-6">
            <div className="flex items-center space-x-6 mb-4">
        <div className="w-14 h-14 flex-shrink-0 overflow-hidden rounded-full">
          <Avatar src={`/avatars/${assistantType}.png`} alt={assistantType} />
        </div>
        <div className="flex-grow">
            <h3 className="text-lg font-semibold flex items-center group relative">
              {t(`assistant${assistantType}Name`)}
              <Info className="w-4 h-4 ml-2 text-muted-foreground cursor-help" />
              <span className="absolute left-0 top-full mt-1 bg-gray-700 text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-64 z-10">
                {t(`assistant${assistantType}Description`)}
              </span>
            </h3>
            <p className="text-sm text-muted-foreground">{t('dailySummary')}</p>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="w-8 h-8 animate-spin text-primary" />
          </div>
        ) : (
          <>
            {summary && (
              <div className="mb-4">
                <p className="text-sm whitespace-pre-wrap">{summary}</p>
                {createdAt && (
                  <p className="text-xs text-muted-foreground mt-2">
                    {t('generatedAt')}: {format(new Date(createdAt), 'PPpp')}
                  </p>
                )}
              </div>
            )}
            {audioUrl && (
              <div className="mt-4 flex items-center space-x-2">
                <Button 
                  onClick={togglePlayPause} 
                  variant="outline" 
                  size="icon"
                  className="w-10 h-10 rounded-full"
                >
                  {isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
                </Button>
                <div className="flex-grow">
                  <audio 
                    ref={audioRef}
                    src={audioUrl} 
                    className="w-full" 
                    onEnded={() => setIsPlaying(false)}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <Button 
          onClick={generateDailySummary} 
          disabled={isLoading}
          className="w-full mt-4"
        >
          <RefreshCw className={cn("w-4 mr-2", isLoading && "animate-spin")} />
          {isLoading ? t('generatingMessage') : t('generateNewSummary')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default VirtualAssistant;