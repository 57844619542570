import React, { createContext, useState, useContext, useEffect } from 'react';
import { setAuthToken, clearAuthToken, login as apiLogin, register as apiRegister, logout as apiLogout } from '../services/api';
import { webSocketService } from '../services/WebSocketService';

interface User {
  id: string;
  username: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (username: string, email: string, password: string) => Promise<void>;
  getToken: () => string | null;
  isTokenValid: () => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const storedUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      if (storedUser && token) {
        setUser(JSON.parse(storedUser));
        setAuthToken(token);
        webSocketService.connect();
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const data = await apiLogin(email, password);
      const { token, user } = data;

      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      // 添加 token 过期时间
      const expiresIn = 36000; // 假设 token 有效期为 1 小时
      const expiresAt = new Date().getTime() + expiresIn * 1000;
      localStorage.setItem('tokenExpiration', expiresAt.toString());
      
      setAuthToken(token);
      webSocketService.connect();
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await apiLogout();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      clearAuthToken();
      webSocketService.disconnect();
      setIsLoading(false);
    }
  };

  const register = async (username: string, email: string, password: string) => {
    setIsLoading(true);
    try {
      const data = await apiRegister(username, email, password);
      const { token, user } = data;

      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      setAuthToken(token);
      webSocketService.connect();
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getToken = () => localStorage.getItem('token');

  const isTokenValid = () => {
    const expiration = localStorage.getItem('tokenExpiration');
    if (!expiration) return false;
    return new Date().getTime() < parseInt(expiration);
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout, register, getToken, isTokenValid }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};