import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';

const ThemeToggle: React.FC = () => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState('normal');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'normal';
    setTheme(savedTheme);
    document.body.className = savedTheme === 'normal' ? '' : `${savedTheme}-mode`;
  }, []);

  const changeTheme = (newTheme: string) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.className = newTheme === 'normal' ? '' : `${newTheme}-mode`;
  };

  return (
    <div className="flex space-x-2">
      <Button onClick={() => changeTheme('normal')} variant={theme === 'normal' ? 'default' : 'outline'} size="sm">
        {t('normalMode')}
      </Button>
      <Button onClick={() => changeTheme('work')} variant={theme === 'work' ? 'default' : 'outline'} size="sm">
        {t('workMode')}
      </Button>
      <Button onClick={() => changeTheme('dark')} variant={theme === 'dark' ? 'default' : 'outline'} size="sm">
        {t('darkMode')}
      </Button>
    </div>
  );
};

export default ThemeToggle;