import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex space-x-2">
      <Button onClick={() => changeLanguage('en')} variant={i18n.language === 'en' ? 'default' : 'outline'} size="sm">
        EN
      </Button>
      <Button onClick={() => changeLanguage('zh')} variant={i18n.language === 'zh' ? 'default' : 'outline'} size="sm">
        中文
      </Button>
    </div>
  );
};

export default LanguageSwitcher;