import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

interface TaskSummaryChartsProps {
    taskStats: {
      todo: number;
      inProgress: number;
      done: number;
    } | null;
    taskAnalytics: {
      totalTasks: number;
      completedTasks: number;
      pendingTasks: number;
      overdueTasks: number;
    } | null;
  }


const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

const TaskSummaryCharts: React.FC<TaskSummaryChartsProps> = ({ taskStats, taskAnalytics }) => {
  const { t } = useTranslation();

  if (!taskStats || !taskAnalytics) {
    return <div>{t('loading')}</div>; // 添加加载状态
  }
  
  const pieChartData = [
    { name: t('todo'), value: taskStats.todo },
    { name: t('inProgress'), value: taskStats.inProgress },
    { name: t('done'), value: taskStats.done },
  ];

  const barChartData = [
    { name: t('total'), tasks: taskAnalytics.totalTasks },
    { name: t('completed'), tasks: taskAnalytics.completedTasks },
    { name: t('overdue'), tasks: taskAnalytics.overdueTasks },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t('taskStatusDistribution')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>{t('taskOverview')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={barChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="tasks" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default TaskSummaryCharts;